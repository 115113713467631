/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivatedRouteSnapshot, Params } from "@angular/router";
import { getRouterSelectors, RouterReducerState } from "@ngrx/router-store";
import { DefaultProjectorFn, MemoizedSelector, createFeatureSelector, createSelector } from "@ngrx/store";
import { RouteParams } from "src/app/enums";

export const routerState = createFeatureSelector<RouterReducerState<any>>("router");

export const { selectQueryParams: selectQueryParamsNgRx, selectUrl } = getRouterSelectors(routerState);

const getRouteParams = (route: ActivatedRouteSnapshot): Params => {
  if (route.children.length === 0) {
    return route.params;
  }

  const combinedChildParams = route.children.reduce((prev: any, childRoute: any) => ({ ...prev, ...getRouteParams(childRoute) }), {});
  return {
    ...route.params,
    ...combinedChildParams,
  };
};

export const selectRouteParams = createSelector(routerState, state => {
  if (!(state as any)?.state?.root) {
    return {};
  }

  return getRouteParams((state as any).state.root);
});

export const selectRouteParam = (routeParam: string): MemoizedSelector<object, string, DefaultProjectorFn<string>> =>
  createSelector(selectRouteParams, routeParams => routeParams[routeParam]);

export const selectQueryParams = createSelector(selectQueryParamsNgRx, params => params || {});

// Route params
export const selectProgramSlug = selectRouteParam(RouteParams.programSlug);
export const selectRewardId = selectRouteParam(RouteParams.rewardId);
export const selectAccessToken = selectRouteParam(RouteParams.accessToken);
export const selectCouponSlug = selectRouteParam(RouteParams.couponSlug);
export const selectCouponCode = selectRouteParam(RouteParams.couponCode);
export const selectPlatform = selectRouteParam(RouteParams.platform);
export const selectCampaignSlug = selectRouteParam(RouteParams.campaignSlug);

export const { selectRouteData } = getRouterSelectors();
