import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, first, tap } from "rxjs";
import { RootState } from "./store";
import * as UserActions from "src/app/store/user/actions";
import * as FromApp from "src/app/store/app/selectors";
import * as FromRouter from "src/app/store/router/selectors";
import { LoadingController } from "@ionic/angular";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  private loading: HTMLIonLoadingElement;

  private readonly queryParams$: Observable<{
    referral?: string;
    utm_campaign?: string;
    utm_source?: string;
    utm_medium?: string;
  }> = this.store.select(FromRouter.selectQueryParams);

  private readonly loading$: Observable<boolean> = this.store.select(FromApp.selectLoading);

  constructor(
    private readonly store: Store<RootState>,
    private readonly loadingCtrl: LoadingController,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.loading = await this.loadingCtrl.create();

    this.loading$
      .pipe(
        tap(async loading => {
          if (loading) this.loading.present();
          else this.loading.dismiss();
        }),
      )
      .subscribe();

    this.queryParams$
      .pipe(
        first(),
        tap(({ referral, utm_campaign: utmCampaign, utm_medium: utmMedium, utm_source: utmSource }) => {
          this.store.dispatch(UserActions.setReferral({ referral }));
          this.store.dispatch(UserActions.setUTMValues({ utm: { utmCampaign, utmMedium, utmSource } }));
        }),
      )
      .subscribe();
  }
}
