/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1293
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MinimalPassDto { 
    type: MinimalPassDto.TypeEnum;
    kind: MinimalPassDto.KindEnum;
}
export namespace MinimalPassDto {
    export type TypeEnum = 0 | 1;
    export const TypeEnum = {
        NUMBER_0: 0 as TypeEnum,
        NUMBER_1: 1 as TypeEnum
    };
    export type KindEnum = 'store-card' | 'coupon';
    export const KindEnum = {
        StoreCard: 'store-card' as KindEnum,
        Coupon: 'coupon' as KindEnum
    };
}


