import { sortRewards } from "src/app/utils/sort-rewards";
import { RootState } from "..";
import { UserState } from "./state";
import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { ClaimedReward, Reward } from "src/app/services/api";

const selectFeature = (state: RootState): UserState => state.user;

export const selectLoading = createSelector(selectFeature, state => state.loading);

export const selectError = createSelector(selectFeature, state => state.error);

export const selectCustomerData = createSelector(selectFeature, state => state.customerData);

export const selectRewards = createSelector(selectFeature, state => [...Object.values(state.rewards)].sort(sortRewards));

export const selectAccessToken = createSelector(selectFeature, state => state.accessToken);

export const selectUserId = createSelector(selectFeature, state => state.userId);

export const selectReferral = createSelector(selectFeature, state => state.referral);

export const selectUTMValues = createSelector(selectFeature, state => state.utm);

export const selectReward = (id: string): MemoizedSelector<RootState, Reward, DefaultProjectorFn<Reward>> =>
  createSelector(selectFeature, state => state.rewards[id]);

export const selectClaimedReward = (id: string): MemoizedSelector<RootState, ClaimedReward, DefaultProjectorFn<ClaimedReward>> =>
  createSelector(selectFeature, state => state.claimedRewards[id]);

export const selectRewardValidDays = (id: string): MemoizedSelector<RootState, number, DefaultProjectorFn<number>> =>
  createSelector(selectReward(id), reward => {
    if (!reward?.validTo) return null;
    const validToTime = new Date(reward.validTo).getTime();
    const days = Math.ceil((validToTime - Date.now()) / (1000 * 3600 * 24));
    return days;
  });
