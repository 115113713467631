import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppRoutes, RouteParams } from "./enums";

const routes: Routes = [
  {
    path: AppRoutes.notFound,
    loadChildren: () => import("./pages/not-found/not-found.module").then(m => m.NotFoundPageModule),
  },
  {
    path: `:${RouteParams.programSlug}`,
    children: [
      {
        path: AppRoutes.check,
        loadChildren: () => import("./pages/check/check.module").then(m => m.CheckPageModule),
      },
      {
        path: AppRoutes.sendPass,
        loadChildren: () => import("./pages/send-pass/send-pass.module").then(m => m.SendPassPageModule),
      },
      {
        path: AppRoutes.register,
        loadChildren: () => import("./pages/register/register.module").then(m => m.RegisterPageModule),
      },
      {
        path: AppRoutes.verify,
        loadChildren: () => import("./pages/validate/validate.module").then(m => m.ValidatePageModule),
      },
      {
        path: AppRoutes.download,
        loadChildren: () => import("./pages/download/download.module").then(m => m.DownloadPageModule),
      },
      {
        path: AppRoutes.downloaded,
        loadChildren: () => import("./pages/downloaded/downloaded.module").then(m => m.DownloadedPageModule),
      },
      {
        path: AppRoutes.profile,
        loadChildren: () => import("./pages/profile/profile.module").then(m => m.ProfilePageModule),
      },
      {
        path: AppRoutes.info,
        loadChildren: () => import("./pages/info/info.module").then(m => m.InfoPageModule),
      },
      {
        path: AppRoutes.coupon,
        loadChildren: () => import("./pages/coupon/coupon.module").then(m => m.CouponPageModule),
      },
      {
        path: AppRoutes.unauthorized,
        loadChildren: () => import("./pages/unauthorized/unauthorized.module").then(m => m.UnauthorizedPageModule),
      },
      {
        path: AppRoutes.unauthorizedDownload,
        loadChildren: () =>
          import("./pages/unauthorized-download/unauthorized-download.module").then(m => m.UnauthorizedDownloadPageModule),
      },
      {
        path: AppRoutes.anonymousCoupon,
        loadChildren: () => import("./pages/anonymous-coupon/anonymous-coupon.module").then(m => m.AnonymousCouponPageModule),
      },
      {
        path: AppRoutes.referrals,
        loadChildren: () => import("./pages/referral/referral.module").then(m => m.ReferralPageModule),
      },
      {
        path: `:${RouteParams.accessToken}`,
        loadChildren: () => import("./pages/auth/auth.module").then(m => m.AuthPageModule),
      },
      {
        path: "**",
        pathMatch: "full",
        redirectTo: AppRoutes.check,
      },
    ],
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: AppRoutes.notFound,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
