import { Reward, CustomerData, ClaimedReward } from "src/app/services/api";

export interface Rewards {
  [key: string]: Reward;
}

export interface ClaimedRewards {
  [key: string]: ClaimedReward;
}

export interface UTM {
  utmCampaign?: string;
  utmSource?: string;
  utmMedium?: string;
}

export interface UserState {
  customerData: CustomerData;
  rewards: Rewards;
  claimedRewards: ClaimedRewards;
  accessToken: string;
  userId: string;
  referral: string;
  utm: UTM;
  error: string;
  loading: boolean;
}

export const initialState: UserState = {
  customerData: null,
  rewards: {},
  claimedRewards: {},
  accessToken: null,
  userId: null,
  referral: null,
  utm: {
    utmCampaign: null,
    utmSource: null,
    utmMedium: null,
  },
  error: null,
  loading: false,
};

export const persistedKeys: (keyof UserState)[] = [];
