/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1293
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Reward { 
    id: string;
    code: string;
    value: string;
    cost: number;
    title: string;
    description: string;
    image: string;
    redeemable: boolean;
    usageType: Reward.UsageTypeEnum;
    validFrom: string;
    validTo: string;
    activatable: boolean;
    active: boolean;
}
export namespace Reward {
    export type UsageTypeEnum = 'DISCOUNT' | 'MULTIPLIER' | 'CAMPAIGN' | 'EXTRAPOINT' | 'INCENTIVE' | 'REWARD';
    export const UsageTypeEnum = {
        Discount: 'DISCOUNT' as UsageTypeEnum,
        Multiplier: 'MULTIPLIER' as UsageTypeEnum,
        Campaign: 'CAMPAIGN' as UsageTypeEnum,
        Extrapoint: 'EXTRAPOINT' as UsageTypeEnum,
        Incentive: 'INCENTIVE' as UsageTypeEnum,
        Reward: 'REWARD' as UsageTypeEnum
    };
}


