/* eslint-disable @typescript-eslint/no-explicit-any */
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as Sentry from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular-ivy";
import { ActionReducer, MetaReducer, StoreModule, Store } from "@ngrx/store";
import { RootState, effects, reducers } from "src/app/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { localStorageSync } from "ngrx-store-localstorage";
import { AppRoutingModule } from "src/app/app-routing.module";
import { AppComponent } from "src/app/app.component";
import { DEFAULT_LANGUAGE } from "src/app/constants";
import { environment } from "src/environments/environment";
import { ApiModule, Configuration } from "src/app/services/api";
import { persistedKeys as appPersistedKeys } from "src/app/store/app/state";
import { persistedKeys as userPersistedKeys } from "src/app/store/user/state";
import { LanguageInterceptor } from "src/app/interceptors/language/language.interceptor";
import { AuthInterceptor } from "src/app/interceptors/auth/auth.interceptor";
import { VersionInterceptor } from "src/app/interceptors/version/version.interceptor";
import { initializeApp } from "./app.initializer.factory";
import { InfoComponentModule } from "./modals/info/info.module";
import { AngularFireModule } from "@angular/fire/compat";
import { SessionIdInterceptor } from "./interceptors/session-id/session-id.interceptor";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AppInterceptor } from "./interceptors/app/app.interceptor";
import { UTMInterceptor } from "./interceptors/utm/utm.interceptor";

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export const programSlug = ((url: string): string => {
  const [, , , programSlug] = url.split("/");
  return programSlug;
})(window.location.href);

export const localStorageReduxSync = (reducer: ActionReducer<RootState>): ActionReducer<any> =>
  localStorageSync({
    keys: [{ app: appPersistedKeys }, { user: userPersistedKeys }],
    rehydrate: true,
    storageKeySerializer: key => `${programSlug}_${environment.name}_${key}`,
  })(reducer);

const metaReducers: MetaReducer<any, any>[] = [localStorageReduxSync];

export const setApiConfig = (): Configuration => new Configuration({ basePath: environment.apiUrl });

Sentry.init(
  {
    release: `jeripass-customer-app@${environment.release}`,
    dsn: "https://abd7bc673faa03bacab5170712d506d0@o146990.ingest.us.sentry.io/4506875639955456",
    environment: environment.name,
    dist: environment.dist,
    attachStacktrace: true,
    autoSessionTracking: true,
    enabled: environment.name === "staging" || environment.name === "production",
  },
  SentryAngular.init,
);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ApiModule.forRoot(setApiConfig),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    InfoComponentModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UTMInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Store],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
