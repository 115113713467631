export enum AppRoutes {
  anonymousCoupon = "anonymous-coupon",
  check = "check",
  coupon = "coupons",
  data = "data",
  download = "download",
  downloaded = "downloaded",
  help = "help",
  info = "info",
  notFound = "not-found",
  profile = "profile",
  register = "register",
  rewards = "rewards",
  sendPass = "send-pass",
  unauthorized = "unauthorized",
  unauthorizedDownload = "unauthorized-download",
  verify = "verify",
  referrals = "referrals",
}

export enum Languages {
  de = "de",
  es = "es",
  en = "en",
}

export enum RouteParams {
  accessToken = "accessToken",
  campaignSlug = "campaignSlug",
  couponCode = "couponCode",
  couponSlug = "couponSlug",
  programSlug = "programSlug",
  rewardId = "rewardId",
  platform = "platform",
}

export enum Platform {
  ios = "ios",
  android = "android",
}
