/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, first, Observable, switchMap } from "rxjs";
import { RootState } from "src/app/store";
import * as FromUser from "src/app/store/user/selectors";

@Injectable({ providedIn: "root" })
export class UTMInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<RootState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([this.store.select(FromUser.selectReferral), this.store.select(FromUser.selectUTMValues)]).pipe(
      first(),
      switchMap(([referral, utm]) => {
        if (referral) req = req.clone({ setHeaders: { referral } });
        if (utm.utmCampaign) req = req.clone({ setParams: { utmCampaign: utm.utmCampaign } });
        if (utm.utmMedium) req = req.clone({ setParams: { utmMedium: utm.utmMedium } });
        if (utm.utmSource) req = req.clone({ setParams: { utmSource: utm.utmSource } });

        return next.handle(req);
      }),
    );
  }
}
